import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DrawingPolyEdit, {  DrawingPolyEditc } from './DrawingPolyEdit';
import { withScriptjs } from "react-google-maps";
import { useNavigate,useLocation ,useParams} from 'react-router-dom';

const drawerWidth = 240
const MapLoader = withScriptjs(DrawingPolyEditc);



export class Editzone extends Component {
  constructor(props) {
    super(props)

    this.state = {
    
 
    }
    this.handleChange = this.handleChange.bind(this)
  }



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }


 

  
  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Edit Delivery Zone</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                    //  href="/addBanners"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      Edit Delivery Zone
                    </Link>
                  </Breadcrumbs>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button disableElevation onClick={()=>this.props.navigation.navigate('/zoneList')} startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Delivery Zone List
                  </Button>
                </Box>
              </Card>








                  <MapLoader
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDoCRzKzLq9OmHRj2aOkrYF9Uxk4IPjq2g&libraries=drawing"
        loadingElement={<div style={{ height: `100%` }} />}
      />





            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>
      </div>
    )
  }
}




export function Editzonec(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (<Editzone location={location} navigate={navigate}></Editzone>)
}






