import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
const drawerWidth = 240



export class Customer_details extends Component {
    constructor(props) {
        super(props)

        this.state = {
            customer_name: "",
            email: "",
            password: "",
            phone: '',
            zone: "",
            created_at: null,
            updated_at: null,
        }
    }


    componentDidMount() {

        fetch(`${statics.baseUrl}/retriveSingleCustomerData`, {
            headers: {
                'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                id: this.props.param.id.replace(/[^\w\s]/gi, '')
            }),

        }).then((res) => { return res.json() }).then((result) => {
            console.log(result)
            this.setState({
                customer_name: result.data?.customer_name,
                email: result.data?.email,
                password: result.data?.password,
                phone: result.data?.phone,
                zone: result.data?.zone_name,
                created_at: result.data?.created_at,
                updated_at: result.data?.updated_at,
            })
        })

    }




    render() {
        return (
            <div>
                <Box sx={{ display: 'flex' }}>
                    <Sidebarc />
                    <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
                        <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Customer details</Typography>



                            <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                                            Dashboard
                                        </Link>

                                        <Link
                                            underline="hover"
                                            color="text.primary"
                                            href="/customerDetails"
                                            aria-current="page"
                                            sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                                        >
                                            Customer details
                                        </Link>
                                    </Breadcrumbs>
                                </Box>
                            </Card>




                            <Card sx={{ minHeight: 200, width: '100%', mt: 1, mb: 1, backgroundColor: '#004b82', objectFit: 'fill' }} variant="outlined">
                                <Typography sx={{ margin: 2, fontSize: 13, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>Customer description :-</Typography>

                                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'space-between' }}>
                                    <Box sx={{ minHeight: 90, width: '100%' }}>


                                        <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>Customer name : {this.state.customer_name}</Typography>
                                        <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>Email id : {this.state.email}</Typography>
                                        <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>Phone :  {this.state.phone}</Typography>

                                    </Box>

                                    <Box sx={{ minHeight: 90, width: '100%' }}>

                                        <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase', display: 'flex' }}>Zone name : <Typography sx={{ backgroundColor: '#1abcfd', fontSize: 11, paddingRight: 0.4, paddingLeft: 0.4, ml: 0.5, borderRadius: 0.3 }}>{this.state.zone}</Typography></Typography>
                                        <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase', display: 'flex' }}>Password : <Typography sx={{ backgroundColor: '#1abcfd', fontSize: 11, paddingRight: 0.4, paddingLeft: 0.4, ml: 0.5, borderRadius: 0.3 }}>{this.state.password}</Typography></Typography>
                                        <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase', display: 'flex' }}>Created at : <Typography sx={{ backgroundColor: '#1abcfd', fontSize: 11, paddingRight: 0.4, paddingLeft: 0.4, ml: 0.5, borderRadius: 0.3 }}>{moment(this.state.created_at).format('YYYY-MM-DD')}</Typography></Typography>


                                    </Box>
                                </Box>

                            </Card>






                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
                    <Appheaderc />
                </Box>




                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </div>
        )
    }
}


export function Customer_detailsc(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams()
    return (<Customer_details param={param} location={location} navigate={navigate}></Customer_details>)
}




