
import React, { Component } from 'react'
//import {Paper} from '@mui/material'
import { Avatar, Box, Container,Link, Paper,Modal ,Menu,Typography ,MenuItem,IconButton,Button, Divider, AppBar, Card} from '@mui/material'
import man from '../src/assets/logo.jpg'
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Toolbar from '@mui/material/Toolbar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import logo from '../src/assets/logo.jpg'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DnsIcon from '@mui/icons-material/Dns';
import { useNavigate,useLocation,useMatch } from 'react-router-dom';
import BugReportIcon from '@mui/icons-material/BugReport';
import PageviewIcon from '@mui/icons-material/Pageview';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import SettingsIcon from '@mui/icons-material/Settings';
import FactCheckIcon from '@mui/icons-material/FactCheck';


import FoodBankIcon from '@mui/icons-material/FoodBank';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LandscapeIcon from '@mui/icons-material/Landscape';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MapIcon from '@mui/icons-material/Map';


export class Appheader extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      openLead: false,
      data: "",
      textcolor: "#008ffb",
      bgcolor: "",
      pathname: this.props.location.pathname,












      is_banner: false,
      is_categories: false,
      is_delivery_zone: false,
      is_sub_category_zone: false,
      is_dish: false,
      is_customer: false,
      is_package: false,
   

    }
    this.setAnchorEl = this.setAnchorEl.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
   
  }


  
 
  first=()=>{
    this.setState({id:1},()=>{
       this.props.navigate('/dashboard');
    });
   
  }
  
  second = ()=>{
    this.setState({id:2},()=>{
      this.props.navigate('/client');
    });
    
  }
  
  third=()=>{
    this.state.opencompaign? this.setState({opencompaign:false}):this.setState({opencompaign:true})
  }
  
  
  forth=()=>{
    this.state.openrole? this.setState({openrole:false}):this.setState({openrole:true})
  }
  
  componentDidMount(){
    if(this.state.pathname=="/state"  || this.state.pathname=="/state/add"){
      this.setState({openResion:true})
    }
  
    if(this.state.pathname=="/area" || this.state.pathname=="/area/add"){
      this.setState({openResion:true})
    }
  
  
    if(this.state.pathname=="/city" || this.state.pathname=="/city/add"){
      this.setState({openResion:true})
    }
  }



compaign=()=>{
  this.props.navigate('/compaign')
  this.setState({opencompaign:true})
}

compaignRequest=()=>{
  this.props.navigate('/compaign/request')
  this.setState({opencompaign:true})
}



handleClick(event) {
  this.setAnchorEl(event.currentTarget);
}
  setAnchorEl(value){
      this.setState({
          anchorEl: value,
          open: !this.state.open
      })
  }
handleClose=() =>{
  this.setAnchorEl(null);
}





renderMenu(){
  return(
  <Menu id="fade-menu"   onClick={this.handleClose} anchorEl={this.state.anchorEl} open={this.state.open}  sx={{mt:1}}>
     <Box sx={{width:180,minHeight:100}}>
       
       <Box sx={{display:'flex',justifyContent:'center'}}>
      
         <Avatar sx={{height:70,width:70,}}>
             <PersonIcon  sx={{color:"#008ffb",height:30,width:30}}/>
         </Avatar>

       </Box>
       <MenuItem  sx={{borderBottom:1,borderColor:'#e0e0e0',color:'#404040',fontSize:{xs:11,sm:12,md:12},fontWeight:'600',color:'red',mt:2,mb:2}} onClick={()=>{this.props.navigate('/'); sessionStorage.clear()}}> <LogoutIcon sx={{ml:1,mr:1}}/> Log Out</MenuItem>
     </Box>
    </Menu>
   )
}







  render() {



    return (
      <div>
      <Container maxWidth='lg'>
<Paper elevation={1}  sx={{height:60,backgroundColor:'#fff',borderRadius:2,position:'fixed',width:'100%',marginLeft:{xs:-2,sm:0}}}>
<Box sx={{display:'flex',justifyContent:'space-between',backgroundColor:'#fff',borderRadius:2}}>


<Box onClick={()=>this.setState({open:true})} sx={{display:'flex',justifyContent:'center',height:60,alignItems:'center'}}>
<IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ ml:1,display:{xs:'block',sm:'none',mt:2} }}
        >
          <MenuIcon sx={{}} />
        </IconButton>

</Box>



<Box sx={{display:'flex',justifyContent:'center',height:60,alignItems:'center'}}>



<Box sx={{marginRight:{xs:1,sm:35},display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>


<Stack spacing={2} direction="row" sx={{display:{xs:'none',sm:'none'}}}>
    <Badge badgeContent={0} color="secondary" anchorOrigin={{
  vertical: 'top',
  horizontal: 'left',
}}>
    <EmailOutlinedIcon  sx={{color:'#42526e'}} />
    </Badge>
    <Badge badgeContent={2} color="success" size='small' anchorOrigin={{
  vertical: 'top',
  horizontal: 'left',
}}>
      <NotificationsActiveOutlinedIcon  sx={{color:'#42526e'}} />
    </Badge>
  </Stack>

<Divider sx={{ height: 32, ml:2,mt:1,mb:1,mr:2,display:{xs:'none',sm:'block' }}} orientation="vertical" />

<Box aria-owns={this.state.open ? 'fade-menu' : undefined} aria-haspopup="true" onClick={this.handleClick}  sx={{display:'flex',flexDirection:'row',minWidth:50}}>

<Avatar alt="Travis Howard">
<PersonOutlinedIcon sx={{height:30,width:30,color:'#0088cc'}}/>
</Avatar>

<Box>
  <Typography sx={{paddingLeft:0.5,paddingRight:0.5,fontSize:13,fontFamily:'sans-serif',fontWeight:'700'}}>{sessionStorage.getItem('adminName')}</Typography>
  <Typography sx={{paddingLeft:0.5,paddingRight:0.5,fontSize:11,fontFamily:'sans-serif',color:'#42526e'}}>Admin</Typography>
</Box> 
{this.renderMenu()}
</Box>
</Box>


</Box>



</Box>
</Paper>
</Container>




<Box sx={{display:{xs:'block',sm:'none'}}}>
<Modal
  open={this.state.open}
  sx={{
    display:{xs:'block',sm:'none'}
  }}
  onClose={this.handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={{display:'flex',justifyContent:'left',alignItems:'center',height:'100%',width:'100%'}} onClick={()=>this.setState({open:false})}>
<Paper sx={{width:'70%',height:'100vh',backgroundColor:'white',borderRight:3,borderColor:'#b0b0b0'}}>


<br/>
<br/>
<br/>





<Paper elevation={1} sx={{ height: 60, backgroundColor: 'white' }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={logo} style={{ height: 60, objectFit: 'cover', width: 100 }} />
              </Box>
            </Paper>

            <Paper elevation={1} sx={{ minHeight: '100vh', backgroundColor: '#fff', mt: 1.5 }}>
              <br />




              <Box sx={{ borderRight: this.state.pathname == "/dashboard" ? 3 : 0, borderRightColor: 'pink' }}>
                <Link to='/dashboard' style={{ textDecoration: 'none' }}>
                  <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/dashboard" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 0.1, borderRadius: 2, alignItems: 'center' }} onClick={this.first}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                      <DashboardIcon sx={{ marginLeft: 2, color: this.state.pathname == "/dashboard" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                      <Box sx={{ width: '100%', marginLeft: '25%' }}>
                        <Link to='/dashboard' style={{ textDecoration: 'none' }}>
                          <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/dashboard" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Dashboard</Typography>
                        </Link>
                      </Box>
                      <Typography sx={{ backgroundColor: '#d30d42', fontSize: 9, textAlign: 'center', fontFamily: 'roboto', borderRadius: 1, pl: 0.7, pr: 0.7, fontWeight: '600', color: '#fff', pt: 0.5, mr: 3 }}>New</Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>






              <Box sx={{ borderRight: this.state.pathname == "/addBanners" ? 3 : this.state.pathname == "/listBanners" ? 3 : 0, borderRightColor: 'pink' }} onClick={() => this.state.is_banner ? this.setState({ is_banner: false }) : this.setState({ is_banner: true })}>
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/addBanners" ? '#ebebf5' : this.state.pathname == "/listBanners" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <LandscapeIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addBanners" ? '#008ffb' : this.state.pathname == "/listBanners" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/addBanners" ? '#008ffb' : this.state.pathname == "/listBanners" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Banners</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/listBanners" ? '#008ffb' : this.state.pathname == "/addBanners" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_banner ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/listBanners" ? '#008ffb' : this.state.pathname == "/addBanners" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_banner ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>




              {
                ///// banner drop down details bottum
              }

              <Box sx={{ display: this.state.is_banner ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addBanners' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addBanners" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addBanners' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addBanners" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Banners</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/listBanners' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/listBanners" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/listBanners'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/listBanners" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>List Banners</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>





              {
                ////// categories   ///////////////// //// /////////////
              }




              <Box sx={{ borderRight: this.state.pathname == "/addCategory" ? 3 : this.state.pathname == "/listCategory" ? 3 : 0, borderRightColor: 'pink' }} onClick={() => this.state.is_categories ? this.setState({ is_categories: false }) : this.setState({ is_categories: true })}>
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/addCategory" ? '#ebebf5' : this.state.pathname == "/listCategory" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <FormatListBulletedIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addCategory" ? '#008ffb' : this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/addCategory" ? '#008ffb' : this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Categories</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/listCategory" ? '#008ffb' : this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_categories ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/listCategory" ? '#008ffb' : this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_categories ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>



              <Box sx={{ display: this.state.is_categories ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addCategory' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addCategory' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Category</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/listCategory' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/listCategory'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>List Categories</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>




              {
                //// delivery zone 
              }



              <Box sx={{ borderRight: this.state.pathname == "/addZone" ? 3 : this.state.pathname == "/zoneList" ? 3 : 0, borderRightColor: 'pink' }} onClick={() => this.state.is_delivery_zone ? this.setState({ is_delivery_zone: false }) : this.setState({ is_delivery_zone: true })}>
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/addZone" ? '#ebebf5' : this.state.pathname == "/zoneList" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <MapIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addZone" ? '#008ffb' : this.state.pathname == "/zoneList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/addZone" ? '#008ffb' : this.state.pathname == "/zoneList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Delivery zone</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/addZone" ? '#008ffb' : this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_delivery_zone ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/zoneList" ? '#008ffb' : this.state.pathname == "/addZone" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_delivery_zone ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>



              <Box sx={{ display: this.state.is_delivery_zone ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addZone' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addZone" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addZone' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addZone" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Delivery Zone</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/zoneList' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/zoneList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/zoneList'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/zoneList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Delivery Zone List</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>









              {
                ///// sub category details
              }

              <Box sx={{ borderRight: this.state.pathname == "/addSubCategory" ? 3 : this.state.pathname == "/subCategoryList" ? 3 : 0, borderRightColor: 'pink' }} onClick={() => this.state.is_sub_category_zone ? this.setState({ is_sub_category_zone: false }) : this.setState({ is_sub_category_zone: true })}>
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/addSubCategory" ? '#ebebf5' : this.state.pathname == "/subCategoryList" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <CategoryIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addSubCategory" ? '#008ffb' : this.state.pathname == "/subCategoryList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/addSubCategory" ? '#008ffb' : this.state.pathname == "/subCategoryList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Sub Category</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/subCategoryList" ? '#008ffb' : this.state.pathname == "/addSubCategory" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_categories ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/subCategoryList" ? '#008ffb' : this.state.pathname == "/addSubCategory" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_categories ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: this.state.is_sub_category_zone ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addSubCategory' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addSubCategory" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addSubCategory' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addSubCategory" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Sub_category</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/subCategoryList' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/subCategoryList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/subCategoryList'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/subCategoryList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Sub_category List</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>






              {
                ///// customer list and details
              }


              <Box sx={{ borderRight: this.state.pathname == "/customerList" ? 3 : this.state.pathname == "/customerList" ? 3 : 0, borderRightColor: 'pink' }} onClick={() => this.state.is_customer ? this.setState({ is_customer: false }) : this.setState({ is_customer: true })}>
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/customerList" ? '#ebebf5' : this.state.pathname == "/customerList" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <Diversity2Icon sx={{ marginLeft: 2, color: this.state.pathname == "/customerList" ? '#008ffb' : this.state.pathname == "/customerList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/customerList" ? '#008ffb' : this.state.pathname == "/customerList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Customer List</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/customerList" ? '#008ffb' : this.state.pathname == "/customerList" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_customer ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/customerList" ? '#008ffb' : this.state.pathname == "/customerList" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_customer ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: this.state.is_customer ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addcustomer' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addcustomer" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addcustomer' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addcustomer" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Customer</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/customerList' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/customerList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/customerList'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/customerList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Customer List</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>




              {
                ///dish section 
              }

              <Box sx={{ borderRight: this.state.pathname == "/addDish" ? 3 : this.state.pathname == "/dishList" ? 3 : 0, borderRightColor: 'pink' }} onClick={() => this.state.is_dish ? this.setState({ is_dish: false }) : this.setState({ is_dish: true })}>
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/addDish" ? '#ebebf5' : this.state.pathname == "/dishList" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <FoodBankIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addDish" ? '#008ffb' : this.state.pathname == "/dishList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/addDish" ? '#008ffb' : this.state.pathname == "/dishList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Dish List</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/dishList" ? '#008ffb' : this.state.pathname == "/addDish" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_dish ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/dishList" ? '#008ffb' : this.state.pathname == "/addDish" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_dish ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: this.state.is_dish ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addDish' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addDish" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addDish' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addDish" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Dish</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/dishList' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/dishList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/dishList'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/dishList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Dish List</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>




              {
                ///// offfer
              }
              <Box sx={{ borderRight: this.state.pathname == "/notiyfication" ? 3 : this.state.pathname == "/notiyfication" ? 3 : 0, borderRightColor: 'pink' }} >
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/notiyfication" ? '#ebebf5' : this.state.pathname == "/notiyfication" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <LocalOfferIcon sx={{ marginLeft: 2, color: this.state.pathname == "/notiyfication" ? '#008ffb' : this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/notiyfication" ? '#008ffb' : this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Offers</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/notiyfication" ? '#008ffb' : this.state.pathname == "/notiyfication" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_categories ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/notiyfication" ? '#008ffb' : this.state.pathname == "/notiyfication" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_categories ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>




              {
                /// notification
              }


              <Box sx={{ borderRight: this.state.pathname == "/notification" ? 3 : this.state.pathname == "/notification" ? 3 : 0, borderRightColor: 'pink' }} >
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/notification" ? '#ebebf5' : this.state.pathname == "/notification" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <CircleNotificationsIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addCategory" ? '#008ffb' : this.state.pathname == "/notification" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link to='/notification' style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/notification" ? '#008ffb' : this.state.pathname == "/notification" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Send Notification</Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>






              {
                /// package model
              }

              <Box sx={{ borderRight: this.state.pathname == "/packageList" ? 3 : this.state.pathname == "/addPackage" ? 3 : 0, borderRightColor: 'pink' }} >
                <Box onClick={()=>this.setState({is_package :  true})} sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/packageList" ? '#ebebf5' : this.state.pathname == "/addPackage" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <MarkunreadMailboxIcon sx={{ marginLeft: 2, color: this.state.pathname == "/packageList" ? '#008ffb' : this.state.pathname == "/addPackage" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link  style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/packageList" ? '#008ffb' : this.state.pathname == "/addPackage" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Packages</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/packageList" ? '#008ffb' : this.state.pathname == "/packageList" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_package ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/packageList" ? '#008ffb' : this.state.pathname == "/addPackage" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_package ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ display: this.state.is_package ? 'block' : 'none' }}>
                <Box  >
                  <Link to='/addPackage' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/addPackage" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link to='/addPackage' style={{ textDecoration: 'none' }}>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/addPackage" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Add Package</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>

                <Box >
                  <Link to='/packageList' style={{ textDecoration: 'none' }}>
                    <Box sx={{ marginLeft: 1, marginRight: 2, height: 34, display: 'flex', justifyContent: 'left', borderRadius: 2, alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <RemoveIcon sx={{ marginLeft: 7, color: this.state.pathname == "/packageList" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                        <Box sx={{ width: '100%', marginLeft: '25%' }}>
                          <Link style={{ textDecoration: 'none' }} to='/packageList'>
                            <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 13, fontWeight: '500', color: this.state.pathname == "/packageList" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Package List</Typography>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </Box>
              </Box>





              {
                /// menu
              }




              <Box sx={{ borderRight: this.state.pathname == "/addCategory" ? 3 : this.state.pathname == "/listCategory" ? 3 : 0, borderRightColor: 'pink' }} >
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/addCategory" ? '#ebebf5' : this.state.pathname == "/listCategory" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <BorderAllIcon sx={{ marginLeft: 2, color: this.state.pathname == "/addCategory" ? '#008ffb' : this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/addCategory" ? '#008ffb' : this.state.pathname == "/listCategory" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Menu</Typography>
                      </Link>
                    </Box>
                    <KeyboardArrowDownIcon sx={{ color: this.state.pathname == "/listCategory" ? '#008ffb' : this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", height: 22, width: 22, display: this.state.is_categories ? 'block' : 'none' }} /> <KeyboardArrowRightIcon sx={{ color: this.state.pathname == "/listCategory" ? '#008ffb' : this.state.pathname == "/addCategory" ? '#008ffb' : "#212121", height: 21, width: 21, display: this.state.is_categories ? 'none' : 'block' }} />
                  </Box>
                </Box>
              </Box>


{
  //// normal order
}
              <Box sx={{ borderRight: this.state.pathname == "/normalOrder" ? 3 : this.state.pathname == "/normalOrder" ? 3 : 0, borderRightColor: 'pink' }} >
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/normalOrder" ? '#ebebf5' : this.state.pathname == "/normalOrder" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <AcUnitIcon sx={{ marginLeft: 2, color: this.state.pathname == "/normalOrder" ? '#008ffb' : this.state.pathname == "/normalOrder" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link to='/normalOrder' style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/normalOrder" ? '#008ffb' : this.state.pathname == "/normalOrder" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Normal Order</Typography>
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>






{

  //// subscribed order
}



              <Box sx={{ borderRight: this.state.pathname == "/subscribed_order" ? 3 : this.state.pathname == "/subscribed_order" ? 3 : 0, borderRightColor: 'pink' }} >
                <Box sx={{ marginLeft: 1, marginRight: 2, height: 38, backgroundColor: this.state.pathname == "/subscribed_order" ? '#ebebf5' : this.state.pathname == "/subscribed_order" ? '#ebebf5' : "#fff", display: 'flex', justifyContent: 'left', mt: 1, borderRadius: 2, alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <CardMembershipIcon sx={{ marginLeft: 2, color: this.state.pathname == "/subscribed_order" ? '#008ffb' : this.state.pathname == "/subscribed_order" ? '#008ffb' : "#212121", height: 20, width: 20 }} />
                    <Box sx={{ width: '100%', marginLeft: '25%' }}>
                      <Link to='/subscribed_order' style={{ textDecoration: 'none' }}>
                        <Typography sx={{ textDecoration: 'none', textAlign: 'left', fontSize: 15, fontWeight: '500', color: this.state.pathname == "/subscribed_order" ? '#008ffb' : this.state.pathname == "/subscribed_order" ? '#008ffb' : "#212121", marginLeft: -4, fontFamily: 'roboto' }}>Subscribed Order</Typography>
                      </Link>
                    </Box>
                  
                  </Box>
                </Box>
              </Box>





              <br />
              <br />
              <br />
            </Paper>
            <br />




























































</Paper>
</Box>
</Modal>
</Box>


    </div>
    )
  }
}

export default Appheader

export function Appheaderc(props){
  const navigate = useNavigate();
  const location = useLocation();
  return (<Appheader location={location} navigate={navigate}></Appheader>)
}
