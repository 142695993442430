import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
const drawerWidth = 240



export class EditPackage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            no_of_days: 0,
            discounts: 0,
            allow_max_days  :0

        }
        this.handleChange = this.handleChange.bind(this)
    }


    
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



componentDidMount(){

    fetch(`${statics.baseUrl}/retriveSinglePackageDetails`, {
        headers: {
            'authorization': `Bearer ${sessionStorage.getItem('token')}`,
            'content-type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          package_id : this.props.param.id.replace(/[^\w\s]/gi, '')
        }),
    }).then((res) => { return res.json() }).then((result) => {
        this.setState({
            title: result.data.title,
            no_of_days:result.data.no_of_days,
            discounts:result.data.discounts,
            allow_max_days  : result.data.allow_max_days
        })
    
    })
}



    alertPackageUnfilled = () => {
        toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Fill All Fields </Typography>, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            icon: "🚀",
            theme: "colored",
        });
    }





    alertPackageUpdate = () => {
        toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Package Updated</Typography>, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            icon: "🚀",
            theme: "colored",
        });
    }


    


    editPackages = () => {
        if (this.state.title !== "" && this.state.no_of_days > 0 && this.state.discounts > 0 && this.state.allow_max_days > 0) {
            fetch(`${statics.baseUrl}/updatePackage`, {
                headers: {
                    'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'content-type': 'application/json',
                },
                method: 'put',
                body: JSON.stringify({
                    title: this.state.title,
                    no_of_days: this.state.no_of_days,
                    discounts: this.state.discounts,
                    allow_max_days  : this.state.allow_max_days,
                    package_id : this.props.param.id.replace(/[^\w\s]/gi, '')
                }),
            }).then((res) => { return res.json() }).then((result) => {
               this.alertPackageUpdate();
            })
        } else {
            this.alertPackageUnfilled();
        }
    }


    render() {
        return (
            <div>
                <Box sx={{ display: 'flex' }}>
                    <Sidebarc />
                    <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
                        <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Edit Package</Typography>



                            <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                                            Dashboard
                                        </Link>

                                        <Link
                                            underline="hover"
                                            color="text.primary"
                                            href="/addBanners"
                                            aria-current="page"
                                            sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                                        >
                                            Edit Package
                                        </Link>
                                    </Breadcrumbs>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Button onClick={() => this.props.navigate('/packageList')} disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                                        Package List
                                    </Button>
                                </Box>
                            </Card>



                            <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">
                                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Edit Packages</Typography>

                                <Box sx={{ ml: 2, mr: 2 }}>

                                    <Typography sx={{ fontSize: { xs: 11, sm: 11, marginTop: 2, marginBottom: 3 }, mt: { xs: 1, sm: 1, md: 1 }, mb: 1, fontWeight: '500', color: '#3e3e40' }}>It Will Required To Upload Package .</Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                                        <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Title</Typography>
                                        </Box>
                                        <TextField  size='small' onChange={this.handleChange} name='title' value={this.state.title} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                                        <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>No of days</Typography>
                                        </Box>
                                        <TextField type='number' size='small' onChange={this.handleChange} name='no_of_days' value={this.state.no_of_days} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                                    </Box>


                                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                                        <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Discounts(%)</Typography>
                                        </Box>
                                        <TextField type='number' size='small' name='discounts' value={this.state.discounts} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                                    </Box>

                                    <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                                        <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>allow_max_days</Typography>
                                        </Box>
                                        <TextField type='number' size='small' name='allow_max_days' value={this.state.allow_max_days} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                                    </Box>

                                    <br />

                                    <Button onClick={this.editPackages} disableElevation startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                                        Edit Apply
                                    </Button>

                                    <br />
                                    <br />

                                </Box>
                            </Card>






                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
                    <Appheaderc />
                </Box>




                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </div>
        )
    }
}


export function EditPackagec(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    return (<EditPackage param={param} location={location} navigate={navigate}></EditPackage>)
}




