

const baseUrl = 'https://kitchen.bizz-manager.com/admin_user'
const assetsURL = 'https://kitchen.bizz-manager.com'



let statusArray = [
    {
        "id": 1, "name": "Publish"
    },
    {
        "id": 2, "name": "Unpublish"
    }
]



export default {
    statusArray,
    baseUrl,
    assetsURL
}




