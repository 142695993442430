import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
const drawerWidth = 240



export class AddBanners extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: "",
      img_url: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangee = this.handleChangee.bind(this)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangee = (e) => {

     if (e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/i)) {
       this.setState({
         [e.target.name]: e.target.files[0]
       })
     } else {
       alert('Not a image type')
     }
      
  }




  alertBannerFillFields = () => {
    toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Fill All Fields </Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }


  alertBannerAdded = () => {
    toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Banner Posted Done</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }




  addBanners = () => {
    if (this.state.img_url!== "" && this.state.status !== "") {
      let formdata = new FormData();
      formdata.append('status', this.state.status)
      formdata.append('img_url', this.state.img_url)

      fetch(`${statics.baseUrl}/addBanners`, {
        headers: {
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
          // 'content-type':'application/json',
        },
        method: 'post',
        body: formdata,

      }).then((res) => { return res.json() }).then((result) => {
        this.alertBannerAdded();
        this.setState({
          image_url : "",status:""
        })
      })
    } else {
      this.alertBannerFillFields();
    }
  }


  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Add Banners</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      href="/addBanners"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      AddBanner
                    </Link>
                  </Breadcrumbs>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button onClick={() => this.props.navigate('/listBanners')} disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Banner List
                  </Button>
                </Box>
              </Card>





              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">

                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Add Banners</Typography>

                <Box sx={{ ml: 2, mr: 2 }}>

                  <Typography sx={{ fontSize: { xs: 11, sm: 11, marginTop: 2, marginBottom: 3 }, mt: { xs: 1, sm: 1, md: 1 }, mb: 1, fontWeight: '500', color: '#3e3e40' }}>It Will Required To Upload To Show Banner In Home Page App.</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Upload Image</Typography>
                    </Box>
                    <TextField size='small' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, startAdornment: <input type='file' key={this.state.img_url} accept="image/*" name='img_url' onChange={this.handleChangee} />, }} />
                  </Box>



                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Status</Typography>
                    </Box>
                    <TextField size='small' name='status' value={this.state.status} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                      {
                        statics.statusArray.map((i) => (
                          <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i.id} value={i.name} onClick={() => this.setState({ status: i.name })}>
                            {
                              i.name
                            }
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>

                  <br />

                  <Button onClick={this.addBanners} disableElevation startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                    Banner
                  </Button>

                  <br />
                  <br />

                </Box>
              </Card>






            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>




        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </div>
    )
  }
}


export function AddBannersc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (<AddBanners location={location} navigate={navigate}></AddBanners>)
}
