import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
const drawerWidth = 240


export class EditCustomerDetails extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
        customer_name: '',
        email_id: '',
        phone_no: '',
        password: '',
        zone: [],
        zoneName : '',
        zone_id : "",



        zoneList: []
      }
      this.handleChange = this.handleChange.bind(this)
      this.handleChangee = this.handleChangee.bind(this)
    }


    handleChange = (e) => {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    
      handleChangee = (e) => {
        this.setState({
          [e.target.name]: e.target.files[0]
        })
      }
    
    



  componentDidMount() {
    const id = this.props.params.id.replace(/[^\w\s]/gi, '');
    fetch(`${statics.baseUrl}/retriveSingleCustomerData`, {
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        id: id
      })

    }).then((res) => { return res.json() }).then((result) => {
      this.setState({
        customer_name: result.data.customer_name,
        email_id: result.data.email,
        phone_no: result.data.phone,
        password: result.data.password,
        zone: result.data.zone_lat_log,
        zoneName : result.data.zone_name,
        zone_id : result.data.zone_id,
      })
    });




    fetch(`${statics.baseUrl}/retriveZoneListForSelect`, {
        headers: {
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
        method: 'get',
  
      }).then((res) => { return res.json() }).then((result) => {
        this.setState({ zoneList: result.data })
      })
  
  }



  alertCustomerFillFields = () => {
    toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Fill All Fields </Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }


  alertCustomerExists=()=>{
    toast.error(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Customer Exists</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }

  alertCustomerAdded = () => {
    toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Customer Edited</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }




  editCustomer=async(req,res)=>{
    //// updateCustomerDetails
    if(this.state.customer_name!=="" && this.state.email_id!=="" && this.state.password!=="" && this.state.phone_no!=="" && this.state.zoneName!==""){
    const id = this.props.params.id.replace(/[^\w\s]/gi, '');
    fetch(`${statics.baseUrl}/updateCustomerDetails`, {
        headers: {
          'authorization': `Bearer ${await sessionStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
        method: 'put',
        body: JSON.stringify({
          id  :  id,
          customer_name: this.state.customer_name,
          email: this.state.email_id,
          phone: this.state.phone_no,
          zone_id: this.state.zone_id,
          zone_name: this.state.zoneName,
          zone_lat_log: this.state.zone,
          password: this.state.password,
        }),
      
      }).then((res) => { return res.json() }).then((result) => {
        if(result.status){
       this.alertCustomerAdded()   
        }else{
          this.alertCustomerExists() 
        }
      })
    }else{
        this.alertCustomerFillFields()
    }
  }


  render() {
    return (
        <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Edit Customer</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      href="/customer"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      Edit customer
                    </Link>
                  </Breadcrumbs>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button onClick={() => this.props.navigate('/customerList')} disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Customer  List
                  </Button>
                </Box>
              </Card>





              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">

                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Edit Customer</Typography>

                <Box sx={{ ml: 2, mr: 2 }}>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Customer Name</Typography>
                    </Box>
                    <TextField size='small' name='customer_name' value={this.state.customer_name} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>


                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Phone No</Typography>
                    </Box>
                    <TextField size='small' name='phone_no' value={this.state.phone_no} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Email  id</Typography>
                    </Box>
                    <TextField size='small' name='email_id' value={this.state.email_id} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>


                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Password</Typography>
                    </Box>
                    <TextField size='small' name='password' value={this.state.password} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Zone</Typography>
                    </Box>
                    <TextField size='small' name='zoneName' value={this.state.zoneName} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                      {
                        this.state.zoneList.map((i) => (
                          <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i._id} value={i.zone_title} onClick={() => this.setState({ zone: i.zone_bound ,zoneName:i.zone_title,zone_id : i._id})}>
                            {
                              i.zone_title
                            }
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>


{
  /*
 <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Status</Typography>
                    </Box>
                    <TextField size='small' name='status' value={this.state.status} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                      {
                        statics.statusArray.map((i) => (
                          <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i.id} value={i.name} onClick={() => this.setState({ status: i.name })}>
                            {
                              i.name
                            }
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>
  */
}
                 

                  <br />

                  <Button onClick={this.editCustomer} disableElevation startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                    Edit Customer
                  </Button>

                  <br />
                  <br />

                </Box>
              </Card>






            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>




        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </div>
    )
  }
}


export function EditCustomerDetailsc(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams()
    return (<EditCustomerDetails params={params} location={location} navigate={navigate}></EditCustomerDetails>)
  }