import React, { Component } from 'react'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Tooltip, IconButton, Card, Avatar, FormControl, Select, ButtonGroup, InputLabel, TableBody, Table, Chip, Checkbox, Autocomplete, Tab, Tabs, Modal, MenuItem, TableContainer, TablePagination, Divider, TableCell, TableHead, TableRow, TextField, InputAdornment, Typography, Box, Button, Paper, SpeedDialIcon, Radio } from '@mui/material'
import statics from '../static'
import EditNoteIcon from '@mui/icons-material/EditNote';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import backgroundimg from '../assets/ss.png'
const drawerWidth = 240




export class Subscription_description extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orderDishList: [],
            allOrderDetails: {},



         
        }

    }






    componentDidMount() {

        fetch(`${statics.baseUrl}/retriveSingleSubscriptionDetails`, {
            headers: {
                'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                id: this.props.param.id.replace(/[^\w\s]/gi, '')
            })
        }).then((res) => { return res.json() }).then((result) => {
            this.setState({
                orderDishList: result.data.ordered_items,
                allOrderDetails: result.data
            })
        })


    }


    render() {
        return (
            <div>
                <Box sx={{ display: 'flex' }}>
                    <Sidebarc />
                    <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
                        <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Subscription description</Typography>

                            <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/dashboard" style={{ fontSize: 14, fontWeight: '600' ,color:'#77787c'}}>
                                            Dashboard
                                        </Link>

                                        <Link
                                            underline="hover"
                                            color="text.primary"
                                            href="/subscribed_order"
                                            aria-current="page"
                                            style={{ fontSize: 14, fontWeight: '600', color: '#3e4147',textTransform:'none', }}
                                        >
                                            Ordered discription list
                                        </Link>
                                    </Breadcrumbs>
                                </Box>
                            </Card>





                            <Card sx={{ minHeight: 200, width: '100%', mt: 1, mb: 1, backgroundColor: '#004b82', objectFit: 'fill' }} variant="outlined">
                                <Typography sx={{ margin: 2, fontSize: 13, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>Subscription description :-</Typography>

                                <Box sx={{ display: 'flex', flexDirection: {xs:'column',sm:'column',md:'row'}, justifyContent: 'space-between' }}>
                                    <Box sx={{ minHeight: 90, width: '100%'}}>
                                   
                                   
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>Customer name : {this.state.allOrderDetails?.customer_name}</Typography>
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>start_date : {moment(this.state.allOrderDetails?.start_date).format('YYYY-MM-DD')}</Typography>
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' }}>tifine_delivered :  {this.state.allOrderDetails?.tifine_delivered}</Typography>
                                 
                                    <Button sx={{marginLeft:2,marginBottom:2,textTransform:'none',height:26,fontSize:13,backgroundColor:'#d30d42'}} disableElevation size='small' variant="contained"><Link to={`/customerDetails/:${this.state.allOrderDetails?.customer_id}`} style={{textDecoration:'none',color:'#fff'}}>see customer details</Link></Button>

                                    </Box>
                                    <Box sx={{ minHeight: 90, width: '100%' }}>

                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' ,display:'flex',flexDirection:'row'}}>Order status : <Button disableElevation sx={{height:18,fontSize:12,marginLeft:1,backgroundColor:'#1abcfd',textTransform:'none',color:'#fff'}}>{this.state.allOrderDetails?.status}</Button></Typography>
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase',display:'flex' }}>Is lunch :  <Typography sx={{backgroundColor:'#1abcfd',fontSize:11,paddingRight:0.4,paddingLeft:0.4,ml:0.5,borderRadius:0.3}}>{this.state.allOrderDetails?.is_lunch?'TRUE': 'FALSE'}</Typography></Typography>
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' ,display:'flex'}}>Is dinner : <Typography sx={{backgroundColor:'#1abcfd',fontSize:11,paddingRight:0.4,paddingLeft:0.4,ml:0.5,borderRadius:0.3}}>{this.state.allOrderDetails?.is_dinner?'TRUE': 'FALSE'}</Typography></Typography>

                                    </Box>
                                    <Box sx={{ minHeight: 90, width: '100%'}}>

                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' ,display:'flex'}}>TOTAL ITEM : <Typography sx={{backgroundColor:'#1abcfd',fontSize:11,paddingRight:0.4,paddingLeft:0.4,ml:0.5,borderRadius:0.3}}>{this.state.allOrderDetails?.total_item} Nos</Typography></Typography>
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' ,display:'flex'}}>TOTAL AMOUNT : <Typography sx={{backgroundColor:'#1abcfd',fontSize:11,paddingRight:0.4,paddingLeft:0.4,ml:0.5,borderRadius:0.3}}>₹ {this.state.allOrderDetails?.total_payment}</Typography></Typography>
                                    <Typography sx={{ margin: 2, fontSize: 11, fontWeight: '500', color: '#fff', textTransform: 'uppercase' ,display:'flex'}}>Dilivery Charges : <Typography sx={{backgroundColor:'#1abcfd',fontSize:11,paddingRight:0.4,paddingLeft:0.4,ml:0.5,borderRadius:0.3}}>0</Typography></Typography>


                                    </Box>
                                </Box>

                            </Card>


                            <Card sx={{ minHeight: 150, width: '100%', mt: 1 }} variant="outlined">
                                <Box sx={{ mt: 0, padding: 2}}>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: 720 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}> ID</TableCell>
                                                    <TableCell align='left' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Dish Name</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Image</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Price</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Quantity</TableCell>
                                                    <TableCell align='right' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.orderDishList.map((row, index) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: index % 2 ? '#f9f9f9' : '#fff' }}
                                                    >

                                                        <TableCell align='left' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>{index + 1}</TableCell>

                                                        <TableCell align='left' sx={{ color: '#42526e', fontSize: 12, fontWeight: '500' }}>
                                                            {row.dish_name}
                                                        </TableCell>


                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600', }}>
                                                            <img src={`${statics.assetsURL}` + '/' + `${row.dish_img}`} style={{ height: 20, width: 40, objectFit: 'cover' }} />
                                                        </TableCell>


                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600', }}>
                                                            {row.price}
                                                        </TableCell>


                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600', }}>
                                                            1
                                                        </TableCell>


                                                        <TableCell align='right' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                                                                <Paper onClick={() => this.setState({ is_delete_popup_open: true, dish_id: row._id })} sx={{ height: 30, width: 30, backgroundColor: '#1abcfd', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <AutoDeleteIcon style={{ height: 15, width: 15, color: '#fff' }} />
                                                                </Paper>
                                                            </Box>
                                                        </TableCell>


                                                    </TableRow>
                                                ))

                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Divider />
                            
                                </Box>






                            </Card>


                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
                    <Appheaderc />
                </Box>






                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </div>
        )
    }
}



export function Subscription_descriptionc(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    return (<Subscription_description param={param} location={location} navigate={navigate}></Subscription_description>)
}









