import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
const drawerWidth = 240

export class SendBulkNotification extends Component {
  constructor(props) {
    super(props)

    this.state = {

      img_url: "",
      message: "",
      heading: "",
      send_to: "",


    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangee = this.handleChangee.bind(this)
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangee = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    })
  }




  alertNotificationSend = () => {
    toast.info(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Notification send</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }


  fillAllFields = () => {
    toast.error(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Fill All Fields</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }



  sendNotification = () => {

    this.alertNotificationSend();
    if (this.state.heading !== "" && this.state.message !== "" && this.state.send_to !== "") {
      /// send notificaton



    } else {
      /// alert fill fields

      this.fillAllFields();

    }

  }



  clear=()=>{
    this.setState({
      img_url: "",
      message: "",
      heading: "",
      send_to: "",
    })
  }

  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Notification section</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      href="/notification"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      Notification
                    </Link>
                  </Breadcrumbs>
                </Box>

              </Card>





              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">

                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Send Notification</Typography>

                <Box sx={{ ml: 2, mr: 2 }}>

                  <Typography sx={{ fontSize: { xs: 11, sm: 11, marginTop: 2, marginBottom: 3 }, mt: { xs: 1, sm: 1, md: 1 }, mb: 1, fontWeight: '500', color: '#3e3e40' }}>Send notification to all  kitchen app user / delivery boy .</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Image Link</Typography>
                    </Box>
                    <TextField size='small' name='img_url' value={this.state.img_url} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, }} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Head Line</Typography>
                    </Box>
                    <TextField size='small' name='heading' value={this.state.heading} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Message</Typography>
                    </Box>
                    <TextField size='small' name='message' value={this.state.message} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Upload Image</Typography>
                    </Box>
                    <TextField size='small' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, startAdornment: <input type='file' accept="image/png, image/jpeg" name='img_url'  onChange={this.handleChangee} />, }} />
                  </Box>

                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 140, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Send To</Typography>
                    </Box>
                    <TextField size='small' name='send_to' value={this.state.send_to} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                      {
                        ['Customer', 'Delivery_boy'].map((i) => (
                          <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i} value={i} >
                            {
                              i
                            }
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>
                  <br />

                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button onClick={this.sendNotification} disableElevation startIcon={<SendIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                      Send Notification
                    </Button>

                    <Button onClick={this.clear} disableElevation size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none', marginLeft: 1 }}>
                      Cancel
                    </Button>
                  </Box>


                  <br />
                  <br />

                </Box>
              </Card>






            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>




        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </div>
    )
  }
}


export function SendBulkNotificationc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (<SendBulkNotification location={location} navigate={navigate}></SendBulkNotification>)
}










