import React, { Component, useEffect, useState } from 'react'
import { Box, Card, Paper, Grid, Divider, Typography, IconButton, Checkbox, Button, Stack, Alert } from '@mui/material'

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import logo from '../assets/logo.jpg'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockIcon from '@mui/icons-material/Lock';


import { useNavigate, useLocation, Link, json, useParams } from 'react-router-dom';
//import base_url from '../base'
function Login(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const { section } = useParams();

    const [showpassword, SetshowPassword] = useState(false);
    const [username, Setusername] = useState("");
    const [password, Setpassword] = useState("");
    const [showAlert, SetshowAlert] = useState(false)
    const [user, Setuser] = useState()







    function autoCloseWarning() {
        setTimeout(() => {
            SetshowAlert(false)
        }, 4000)
    }


    function login() {
        if (username == "admin" && password == '123456') {
            window.open('/dashboard')
        } else {
            alert('credential wrong')
        }

    }
        return (
            <div>
                <Box sx={{ backgroundColor: "#f8f9ff", width: '100%', height: '100vh', backgroundImage: { xs: '', sm: '', md: '' }, objectFit: 'cover' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', height: { xs: 500, sm: 500 }, width: { xs: '90%', sm: 300, md: 400 }, position: 'absolute', top: { xs: 40, sm: 60, md: 70 } }}>
                            <Card sx={{ height: '100%', width: '100%', borderRadius: 3 }}>

                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>


                                                    <Box sx={{ height: 500, marginLeft: { xs: 3, sm: 3, md: 5 }, marginRight: { xs: 3, sm: 3, md: 5 } }}>

                                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                                            <img style={{ height: 120 }} src={logo} />
                                                        </Box>



                                                        <Typography sx={{ textAlign: 'left', fontSize: { xs: 11, sm: 13 }, fontWeight: '600', marginTop: 3, marginBottom: 0.1 }}>Email Id</Typography>
                                                        <Box sx={{ border: 1, borderRadius: 1, borderColor: '#a2a2a6' }}>
                                                            <TextField
                                                                size='small'
                                                                sx={{
                                                                    padding: 0.5, "& input::placeholder": {
                                                                        fontSize: "13px"
                                                                    }
                                                                }}
                                                                fullWidth
                                                                id="input-with-icon-textfield"
                                                                placeholder='Email Id'
                                                                name='user_name'
                                                                onChange={(e) => Setusername(e.target.value.trim())}
                                                                value={username}
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <PersonIcon style={{ color: '#a2a2a6' }} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                            />

                                                        </Box>


                                                        <Typography sx={{ textAlign: 'left', fontSize: { xs: 11, sm: 13 }, fontWeight: '600', marginTop: 1, marginBottom: 0.1 }}>Password</Typography>
                                                        <Box sx={{ border: 1, borderRadius: 1, borderColor: '#a2a2a6' }}>
                                                            <TextField
                                                                id="filled-start-adornment"
                                                                sx={{
                                                                    padding: 0.5, "& input::placeholder": {
                                                                        fontSize: "13px"
                                                                    }
                                                                }}
                                                                name="password"
                                                                autoComplete='off'
                                                                value={password}
                                                                onChange={(e) => Setpassword(e.target.value.trim())}
                                                                placeholder='Your Password'
                                                                size='small'
                                                                fullWidth
                                                                type={showpassword ? 'text' : 'password'}
                                                                InputProps={{

                                                                    disableUnderline: true,
                                                                    endAdornment: (
                                                                        <InputAdornment position="start">
                                                                            {
                                                                                showpassword ? <Visibility style={{ color: '#a2a2a6' }} onClick={() => SetshowPassword(false)} /> : <VisibilityOff onClick={() => SetshowPassword(true)} style={{ color: '#a2a2a6' }} />
                                                                            }
                                                                        </InputAdornment>
                                                                    ),
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <LockIcon style={{ color: '#a2a2a6' }} />
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                variant="standard"
                                                            />

                                                        </Box>



                                                        <Typography sx={{ textAlign: 'right', color: 'red', fontSize: '13px', marginTop: 2 }}></Typography>


                                                        <Box sx={{ marginTop: 8 }}>
                                                            <Button onClick={login} type='submit' variant="contained" fullWidth size='small' sx={{ backgroundColor: '#2f84cf ' }}>Login</Button>
                                                        </Box>







                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>

                                </Grid>
                            </Card>
                        </Box>
                    </Box>
                </Box>


                <Box>
                </Box>
            </div>
        )
    }

    export default Login




























