import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  Polygon
} from "react-google-maps";
import { Button, Card, Box, Typography, TextField, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import statics from '../static'
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const google = window.google;





class DrawingPoly extends Component {
  constructor(props) {
    super(props);

    this.state = {

      zone_name: "",
      status: "",
      polygonlatlong: [],


      drawingControlEnabled: true,
      polygon: null,
      visible: true
    };
    this.handleChange = this.handleChange.bind(this)
  }


  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }



  fiiAllFields = () => {
    toast.error(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Fill All Fields</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }




  addzonealert = () => {
    toast.error(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Zone added</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }



  saveLatLong = () => {
    if (this.state.status !== "" && this.state.zone_name !== "" && this.state.polygonlatlong.length != 0) {

      fetch(`${statics.baseUrl}/addZone`, {
        headers: {
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          zone_name: this.state.zone_name,
          status: this.state.status,
          polygonlatlong: this.state.polygonlatlong,

        })

      }).then((res) => { return res.json() }).then((result) => {
        this.addzonealert()

      })
    } else {
      this.fiiAllFields();
    }
  }






  overlay = e => {
    var location = e.overlay.getPaths().getArray();
    let str = JSON.stringify(location.toString());

    var polygonBounds = e.overlay.getPath();
    var testArray = [];

    for (var a = 0; a < polygonBounds.length; a++) {
      let lat = polygonBounds.getAt(a).lat();
      let log = polygonBounds.getAt(a).lng()
      testArray.push({ lat, log });
    }

    this.setState({
      polygonlatlong: testArray,
      drawingControlEnabled: false
    });

    switch (e.type) {
      case "polygon":
        this.setState({
          polygon: e.overlay.getPaths()
        });
        break;
    }
  };


  render() {

    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{ lat: 18.516726, lng: 73.856255 }}
        defaultZoom={3}
      >
        <DrawingManager
          onOverlayComplete={this.overlay}

          onPolygonComplete={value => console.log(value.getPaths(value))}
          options={{
            drawingControlOptions: {
              position: google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [
                //  google.maps.drawing.OverlayType.CIRCLE,
                google.maps.drawing.OverlayType.POLYGON,
                //   google.maps.drawing.OverlayType.POLYLINE,
                //  google.maps.drawing.OverlayType.RECTANGLE
              ]
            },
            polygonOptions: {
              fillColor: `#2196F3`,
              strokeColor: `#d30d42`,
              fillOpacity: 0.5,
              strokeWeight: 5,
              clickable: true,
              // editable: true,
              //draggable: true,
              zIndex: 1
            }
          }}

        />

        {this.state.polygon !== null && <Polygon paths={this.state.polygon} />}

      </GoogleMap>



    ));



    return (
      <div>



        <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">
          <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Add Zone</Typography>
          <Box sx={{ ml: 2, mr: 2 }}>


            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
              <Box sx={{ height: 37, minWidth: 110, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff', display: "inline" }}>Zone Title</Typography>
              </Box>
              <TextField size='small' name='zone_name' value={this.state.zone_name} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
              <Box sx={{ height: 37, width: 100, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Status</Typography>
              </Box>
              <TextField size='small' name='status' value={this.state.status} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                {
                  [{ id: 1, name: 'Publish' }, { id: 1, name: 'Unpublish' }].map((i) => (
                    <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i.id} value={i.name} onClick={() => this.setState({ status: i.name })}>
                      {
                        i.name
                      }
                    </MenuItem>
                  ))
                }
              </TextField>
            </Box>

            <br />


            <GoogleMapExample
              containerElement={<div style={{ height: `400px`, width: "100%" }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
            <br />

            <Button onClick={this.saveLatLong} disableElevation startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
              Add Delivery Zone
            </Button>


            <br />
            <br />

          </Box>
        </Card>


        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />


      </div>
    );
  }
}

export default DrawingPoly;



