import React, { Component } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { Sidebarc } from './Sidebar'
import { Appheaderc } from './Appheader'
import Diversity3Icon from '@mui/icons-material/Diversity3';
import CountUp from 'react-countup';
import LandscapeIcon from '@mui/icons-material/Landscape';
import statics from './static'
import ApexCharts from 'apexcharts'
import CategoryIcon from '@mui/icons-material/Category';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MapIcon from '@mui/icons-material/Map';

import FoodBankIcon from '@mui/icons-material/FoodBank';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';



const drawerWidth = 240;
export class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      total_banner: 0,
      total_category: 0,
      total_customer: 0,
      total_delivery_zone: 0,
      total_dish: 0,
      total_order: 0,
      total_subscription: 0
    }
  }





  componentDidMount(){
    fetch(`${statics.baseUrl}/retriveDashboardDetails`, {
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
         'content-type':'application/json',
      },
      method: 'get',
      
    }).then((res) => { return res.json() }).then((result) => {
      this.setState({
        total_banner: result.total_banner,
        total_category: result.total_category,
        total_customer: result.total_customer,
        total_delivery_zone: result.total_delivery_zone,
        total_dish: result.total_dish,
        total_order: result.total_order,
        total_subscription: result.total_subscription
      })
    })

  }




  render() {
    return (
      <div>

        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 2, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e4147' }}>Dashboard</Typography>


              <Grid container spacing={1.5}>
                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL BANNER</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_banner} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <LandscapeIcon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL CATEGORY</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_category} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <CategoryIcon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>





                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL CUSTOMER</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_customer} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Diversity3Icon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>





                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>DILIVERY ZONE</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_delivery_zone} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <LocationOnIcon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>





                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL DISH LIST</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_dish} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <FoodBankIcon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL ORDER</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_order} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <FoodBankIcon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL SUBSCRIPTION</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={this.state.total_subscription} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Diversity3Icon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL OFFERS</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={0} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <MarkunreadMailboxIcon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>TOTAL OFFERS</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={0} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Diversity3Icon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>PENDING ORDER</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={0} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Diversity3Icon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>PROCESSING ORDER</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={0} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Diversity3Icon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Paper elevation={0} sx={{ height: 110, backgroundColor: '#008ffb', width: '100%', borderRadius: 2 }}>
                    <Box sx={{ padding: 1.5 }}>
                      <Typography sx={{ fontSize: 12, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff' }}>PROCESSING ORDER</Typography>
                      <Typography sx={{ fontSize: 19, fontWeight: '550', fontFamily: 'sans-serif', color: '#fff', mt: 1 }}><CountUp start={0} end={0} /></Typography>
                      <Box sx={{ width: '100%', height: 40, display: 'flex', justifyContent: 'right' }}>
                        <Box sx={{ height: 35, width: 35, backgroundColor: ' #fff', borderRadius: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Diversity3Icon sx={{ height: 30, width: 30, color: '#1abcfd' }} />
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>


              </Grid>





































            </Box>
          </Box>
        </Box>


        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>


      </div>
    )
  }
}

export default Dashboard