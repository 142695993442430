import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import statics from '../static'
const drawerWidth = 240




export class EditCategory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category_name: "",
      category_upload_img: "",
      category_cover_upload_img: '',
      status: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangee = this.handleChangee.bind(this)
  }






  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  handleChangee = (e) => {
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png|gif)$/i)) {
      this.setState({
        [e.target.name]: e.target.files[0]
      })
    } else {
      alert('Not a image type')
    }
  }


  componentDidMount() {
    //// handling docs

    const id = this.props.params.id.replace(/[^\w\s]/gi, '');

    fetch(`${statics.baseUrl}/retriveSingleCategoryDetails`, {
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        id: id
      })

    }).then((res) => { return res.json() }).then((data) => {
      this.setState({
        category_name: data.category_name,
        category_upload_img: data.category_upload_img,
        category_cover_upload_img: data.category_cover_upload_img,
        status: data.status,
      })
    })

  }





  EditCategory = () => {
    const id = this.props.params.id.replace(/[^\w\s]/gi, '');

    const formData = new FormData();
    formData.append('category_name', this.state.category_name)
    formData.append('category_upload_img', this.state.category_upload_img)
    formData.append('category_cover_upload_img', this.state.category_cover_upload_img)
    formData.append('status', this.state.status)
    formData.append('id',id)

    if (this.state.status !== "" && this.state.category_cover_upload_img !== "" && this.state.category_upload_img !== "" && this.state.category_name !== "") {
      fetch(`${statics.baseUrl}/editCategory`, {
        headers: {
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
          //'content-type': 'application/json',
        },
        method: 'put',
        body: formData

      }).then((res) => { return res.json() }).then((result) => {
        this.props.navigate('/listCategory')
      })

    } else {
      alert("fill all fields")
    }

  }

  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Edit Category</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      href="/addBanners"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      Edit Category
                    </Link>
                  </Breadcrumbs>
                </Box>


                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Category List
                  </Button>
                </Box>
              </Card>





              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">
                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Edit Category</Typography>
                <Box sx={{ ml: 2, mr: 2 }}>




                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, minWidth: 110, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff', display: "inline" }}>Enter Title</Typography>
                    </Box>
                    <TextField size='small' name='category_name' value={this.state.category_name} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>





                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, minWidth: 120, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff', display: "inline" }}>Category Upload</Typography>
                    </Box>
                    <TextField value={this.state.category_upload_img} size='small' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, startAdornment: <input type='file' accept="image/png, image/jpeg" name='category_upload_img' onChange={this.handleChangee} />, }} />
                  </Box>




                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, minWidth: 170, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography display='inline' sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Category Cover Upload</Typography>
                    </Box>
                    <TextField size='small' value={this.state.category_cover_upload_img} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, startAdornment: <input type='file' accept="image/png, image/jpeg" name='category_cover_upload_img' onChange={this.handleChangee} />, }} />
                  </Box>



                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 100, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Status</Typography>
                    </Box>
                    <TextField size='small' name='status' value={this.state.status} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                      {
                        [{ id: 1, name: 'Publish' }, { id: 1, name: 'Unpublish' }].map((i) => (
                          <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i.id} value={i.name} onClick={() => this.setState({ status: i.name })}>
                            {
                              i.name
                            }
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>

                  <br />

                  <Button onClick={this.EditCategory} disableElevation startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                    Edit Category
                  </Button>

                  <br />
                  <br />

                </Box>
              </Card>






            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>
      </div>
    )
  }
}






export function EditCategoryc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (<EditCategory location={location} params={params} navigate={navigate}></EditCategory>)
}



