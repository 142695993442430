import logo from './logo.svg';
import './App.css';
import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, Paper, Typography, } from '@mui/material'
import {
  createBrowserRouter,RouterProvider,
} from "react-router-dom";
import Dashboard from './Dashboard';
import { useNavigate,useLocation } from 'react-router-dom';
//// banners
import AddBanners, { AddBannersc } from '../src/banner/AddBanners'
import EditBanners, { EditBannersc } from '../src/banner/EditBanners'
import ListBanners, { ListBannersc } from '../src/banner/ListBanners'


////// category
import AddCategory, { AddCategoryc } from '../src/category/AddCategory'
import EditCategory, { EditCategoryc } from '../src/category/EditCategory'
import ListCategory, { ListCategoryc } from '../src/category/ListCategory'


/////// zone list 

import AddZone, { Addzonec } from '../src/deliveryzone/Addzone'
import Editzone, { Editzonec } from '../src/deliveryzone/Editzone'
import Zonelist, { Zonelistc } from '../src/deliveryzone/Zonelist'

/////
import { withScriptjs } from "react-google-maps";

import DrawingPoly from './deliveryzone/DrawingPoly';
import AddSubCategory, { AddSubCategoryc } from './subcategory/AddSubCategory';
import EditSubCategory, { EditSubCategoryc } from './subcategory/EditSubCategory';
import SubCategoryList, { SubCategoryListc } from './subcategory/SubCategoryList';
import Customer, { Customerc } from './customer/Customer';
import { DishListc } from './dish/DishList';
import { AddDishc } from './dish/AddDish';
import { AddDiliveryBoyc } from './deliveryboy/AddDiliveryBoy';
import AddOffers from './offers/AddOffers';
import OfferList from './offers/OfferList';
import { EditDishc } from './dish/EditDish';
import {AddCustomerc} from './customer/AddCustomer';
import SendBulkNotification, { SendBulkNotificationc } from './notification/SendBulkNotification';
import { PackageListc } from './Packages/PackageList';
import { AddPackage, AddPackagec } from './Packages/AddPackage';
import { EditPackagec } from './Packages/EditPackage';
import { Normal_orderc } from './normal_order/Normal_order';
import { Subscribed_orderc } from './subcribed_order/Subscribed_order';
import { Order_descriptionc } from './normal_order/Order_description';
import Customer_details, { Customer_detailsc } from './customer/Customer_details';
import { Subscription_description, Subscription_descriptionc } from './subcribed_order/Subscription_description';
import Login from './security/Login';
import { EditCustomerDetailsc } from './customer/EditCustomerDetails';


const router = createBrowserRouter([
  {
    path: "/",
    element:   <Login/>,
  },



  {
    path: "/dashboard",
    element:   <Dashboard/>,
  },

  ////// banners

  
  {
    path: "/addBanners",
    element:   <AddBannersc/>,
  },
  {
    path: "/addBanners/:id",
    element:   <EditBannersc/>,
  },
  {
    path: "/listBanners",
    element:   <ListBannersc/>,
  },





////// category

{
  path: "/addCategory",
  element:   <AddCategoryc/>,
},
{
  path: "/addCategory/:id",
  element:   <EditCategoryc/>,
},
{
  path: "/listCategory",
  element:   <ListCategoryc/>,
},




  //// zone list 


{
  path: "/addZone",
  element:   <Addzonec/>,
},
{
  path: "/addZone/:id",
  element:   <Editzonec/>,
},
{
  path: "/zoneList",
  element:   <Zonelistc/>,
},



//// subb category


{
  path: "/addSubCategory",
  element:   <AddSubCategoryc/>,
},
{
  path: "/addSubCategory/:id",
  element:   <EditSubCategoryc/>,
},
{
  path: "/subCategoryList",
  element:   <SubCategoryListc/>,
},


////// customer model ///



{
  path: "/customer",  /// list
  element:   <Customerc/>,
},
{
  path: "/addcustomer",
  element:   <AddCustomerc/>,
},
{
  path: "/addcustomer/:id",
  element:   <EditCustomerDetailsc/>,
},



////// dish list

{
  path: "/dishList",
  element:   <DishListc/>,
},
{
  path: "/addDish",
  element:   <AddDishc/>,
},
{
  path: "/addDish/:id",
  element:   <EditDishc/>,
},
///// delivery boy
{
  path: "/addDeleveryBoy",
  element:   <AddDiliveryBoyc/>,
},



//// offers
{
  path: "/addOffers",
  element:   <AddOffers/>,
},
{
  path: "/offerList",
  element:   <OfferList/>,
},



////// customer model

{
  path: "/customerList",
  element:   <Customerc/>,
},
{
  path: "/customerDetails/:id",
  element:   <Customer_detailsc/>,
},


//////// notification job
{
  path: "/notification",
  element:   <SendBulkNotificationc/>,
},


///// package display here

{
  path: "/packageList",
  element:   <PackageListc/>,
},

{
  path: "/addPackage",
  element:   <AddPackagec/>,
},

{
  path: "/addPackage/:id",
  element:   <EditPackagec/>,
},


////// normal orderList
{
  path: "/normalOrder",
  element:   <Normal_orderc/>,
},
{
  path: "/normalOrder/orderDescription/:id",
  element:   <Order_descriptionc/>,
},
//// subscribed order

{
  path: "/subscribed_order",
  element:   <Subscribed_orderc/>,
},
{
  path: "/subscribed_order_description/:id",
  element:   <Subscription_descriptionc/>,
},



]);



function App() {

  return (
    <div style={{backgroundColor:'#eef1f9'}}>
   

 
    <RouterProvider router={router} />









    </div>
  );
}

export default App;
