import React, { Component } from 'react'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Tooltip, IconButton, Card, Avatar, FormControl, Select, ButtonGroup, InputLabel, TableBody, Table, Chip, Checkbox, Autocomplete, Tab, Tabs, Modal, MenuItem, TableContainer, TablePagination, Divider, TableCell, TableHead, TableRow, TextField, InputAdornment, Typography, Box, Button, Paper, SpeedDialIcon } from '@mui/material'
import statics from '../static'
import EditNoteIcon from '@mui/icons-material/EditNote';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert';
const drawerWidth = 240




export class Subscribed_order extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 10,
            search: "",
            size: 0,


            orderList: [],
            is_delete_popup_open: false,
            order_id: ''
        }  
    }



    componentDidMount() {
        fetch(`${statics.baseUrl}/retriveSubscriptionList`, {
            headers: {
                'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                search: this.state.search,
            })
        }).then((res) => { return res.json() }).then((result) => {
            this.setState({
                orderList: result.data,
                size: result.size
            })
        })
    }


    instantRetriveOrderList = () => {
        fetch(`${statics.baseUrl}/retriveSubscriptionList`, {
            headers: {
                'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                page: this.state.page,
                rowsPerPage: this.state.rowsPerPage,
                search: this.state.search,
            })

        }).then((res) => { return res.json() }).then((result) => {
            this.setState({
                orderList: result.data,
                size: result.size
            })
        })

    }



    deleteorder = () => {
        toast.error(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Order Deleted</Typography>, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            icon: "🚀",
            theme: "colored",
        });
    }



    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        }, () => {
            this.instantRetriveOrderList();
        })
    };



    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, () => {
            this.instantRetriveOrderList();
        })
    };



    handleChangeSearch=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        },()=>{
            this.instantRetriveOrderList();
        })
    }


    render() {
        return (
            <div>
                <Box sx={{ display: 'flex' }}>
                    <Sidebarc />
                    <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
                        <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Subscription List</Typography>

                            <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                                            Dashboard
                                        </Link>

                                        <Link
                                            underline="hover"
                                            color="text.primary"
                                            href="/subscribed_order"
                                            aria-current="page"
                                            sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                                        >
                                        Subscribe Order List
                                        </Link>
                                    </Breadcrumbs>
                                </Box>
                            </Card>





                            <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">
                                <Box sx={{ display: 'flex', justifyContent: 'left', ml: 2, mt: 2 }}>
                                    <TextField size='small' onChange={this.handleChangeSearch} InputProps={{ sx: { fontSize: 12, width: 200, fontWeight: 600 } }} InputLabelProps={{ sx: { fontSize: 10 } }} name='search' placeholder='search' />
                                </Box>

                                <Box sx={{ mt: 0, padding: 2 }}>
                                    <TableContainer component={Box}>
                                        <Table sx={{ minWidth: 1620 }} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align='left' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}> ID</TableCell>
                                                    <TableCell align='left' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Customer name</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Order_at</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Start date</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>tifin delivered</TableCell>


                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Is_lunch</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Is_dinner</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Is_cancel</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Is_paushed</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Active</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Packed Name</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Days</TableCell>
                                                    <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Discounts</TableCell>
                                                    <TableCell align='right' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' ,marginRight:40}}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.orderList.map((row, index) => (
                                                    <TableRow
                                                        key={row.name}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: index % 2  ? '#f9f9f9' : '#fff' }}
                                                    >
                                                        <TableCell align='left' sx={{ color: '#42526e', fontSize: 13, fontWeight: '600' }}>{index + 1}</TableCell>
                                                        <TableCell align='left' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}>{row.customer_name}</TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}>{moment(row.order_created_at).format('YYYY-MM-DD')}</TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}>{moment(row.start_date).format('YYYY-MM-DD')}</TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}>{row.tifine_delivered}</TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}><Checkbox checked={row.is_lunch} size='small' style={{color:'#1abcfd'}}/></TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}><Checkbox checked={row.is_dinner} size='small'  style={{color:'#1abcfd'}}/></TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}><Checkbox checked={row.is_cancel} size='small' style={{color:row.is_cancel?'#bc1d0f': '#1abcfd'}}/></TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}><Checkbox checked={row.is_pushed} size='small'  style={{color:row.is_pushed?'#f18d03' : '#1abcfd'}}/></TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}><Checkbox checked={!row.is_pushed} size='small'  style={{color:row.is_pushed?'#3db10a' : '#3db10a'}}/></TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '500' }}>{row.packageDetails?.title}</TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '500' }}>{row.packageDetails?.no_of_days}</TableCell>
                                                        <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '500' }}>{row.packageDetails?.discounts}%</TableCell>
                                                        <TableCell align='right' sx={{ color: '#42526e', fontSize: 13, fontWeight: '500' }}><Button onClick={()=>this.props.navigate('/subscribed_order_description/:' + `${row._id}`)} size='small' style={{height:20,width:50,fontSize:10,fontWeight:'600',backgroundColor:'#1abcfd',color:'#fff'}}>View</Button></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Divider />
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={this.state.size}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onPageChange={this.handleChangePage}
                                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                                    />
                                </Box>



                            </Card>

                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
                    <Appheaderc />
                </Box>






                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </div>
        )
    }
}



export function Subscribed_orderc(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return (<Subscribed_order location={location} navigate={navigate}></Subscribed_order>)
}



