
import React, { Component } from 'react'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';

import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Tooltip, IconButton, Card, Avatar, FormControl, Select, ButtonGroup, InputLabel, TableBody, Table, Chip, Checkbox, Autocomplete, Tab, Tabs, Modal, MenuItem, TableContainer, TablePagination, Divider, TableCell, TableHead, TableRow, TextField, InputAdornment, Typography, Box, Button, Paper, SpeedDialIcon } from '@mui/material'
import statics from '../static'
import EditNoteIcon from '@mui/icons-material/EditNote';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from  'react-router-dom'
const drawerWidth = 240



export class PackageList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 10,
      search: "",
      size: 0,






      packageList: [],
      is_delete_popup_open: false,
      package_id: ''
    }
    this.handleChangeSearch = this.handleChangeSearch.bind(this)
  }




  handleChangeSearch = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    }, () => {

      this.instantRetrivePackageList();

    })
  }





  componentDidMount() {
    fetch(`${statics.baseUrl}/retrivePackage`, {
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        search: this.state.search,
      })

    }).then((res) => { return res.json() }).then((result) => {
      this.setState({
        packageList: result.data,
        size: result.size
      })
    })
  }





  instantRetrivePackageList = () => {
    fetch(`${statics.baseUrl}/retrivePackage`, {
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        page: this.state.page,
        rowsPerPage: this.state.rowsPerPage,
        search: this.state.search,
      })

    }).then((res) => { return res.json() }).then((result) => {
      this.setState({
        packageList: result.data,
        size: result.size
      })
    })
  }



  deletePackages = () => {
    toast.error(<Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>Package Deleted</Typography>, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      draggable: true,
      progress: undefined,
      icon: "🚀",
      theme: "colored",
    });
  }






  delete = () => {
    fetch(`${statics.baseUrl}/deletePackage`, {
      headers: {
        'authorization': `Bearer ${sessionStorage.getItem('token')}`,
        'content-type': 'application/json',
      },
      method: 'delete',
      body: JSON.stringify({
        package_id: this.state.package_id
      })

    }).then((res) => { return res.json() }).then((result) => {
      this.setState({
        is_delete_popup_open: false
      }, () => {
        this.deletePackages();
        this.instantRetrivePackageList();
      })
    })

  }



  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    }, () => {
      this.instantRetrivePackageList();
    })
  };



  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }, () => {
      this.instantRetrivePackageList();
    })
  };






  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Package List</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to="/dashboard" style={{ fontSize: 14, fontWeight: '600',textDecoration:'none'}}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      to="/packageList"
                      aria-current="page"
                      style={{ fontSize: 14, fontWeight: '600', color: '#3e4147' ,textDecoration:'none'}}
                    >
                      Package List
                    </Link>
                  </Breadcrumbs>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button onClick={() => this.props.navigate('/addPackage')} disableElevation startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Add package
                  </Button>
                </Box>
              </Card>


              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'left', ml: 2, mt: 2 }}>
                  <TextField size='small' InputProps={{ sx: { fontSize: 12, width: 200, fontWeight: 600 } }} InputLabelProps={{ sx: { fontSize: 10 } }} placeholder='search' name='search' onChange={this.handleChangeSearch} />
                </Box>


                <Box sx={{ mt: 0, padding: 2 }}>
                  <TableContainer component={Box}>
                    <Table sx={{ minWidth: 720 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell align='left' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}> ID</TableCell>
                          <TableCell align='left' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Title</TableCell>
                          <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>No of days</TableCell>
                          <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Discount</TableCell>
                          <TableCell align='center' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Max days</TableCell>
                          <TableCell align='right' sx={{ fontSize: 13, fontWeight: '600', color: '#919191' }}>Action</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.packageList.map((row, index) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: index % 2 ? '#f9f9f9' : '#fff' }}
                          >
                            <TableCell align='left' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>{index + 1}</TableCell>
                            <TableCell align='left' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>{row.title}</TableCell>

                            <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>{row.no_of_days}</TableCell>

                            <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>{row.discounts}</TableCell>
                            <TableCell align='center' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>{row.allow_max_days}</TableCell>
                            <TableCell align='right' sx={{ color: '#42526e', fontSize: 12, fontWeight: '600' }}>
                              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>

                                <Paper sx={{ height: 30, width: 30, backgroundColor: '#008ffb', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 1 }}>
                                  <Link to={`/addPackage/:${row._id}`} style={{ height: 30, width: 30, backgroundColor: '#008ffb', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3 }}>
                                    <EditNoteIcon style={{ height: 17, width: 17, color: '#fff' }} />
                                  </Link>
                                </Paper>


                                <Paper onClick={() => this.setState({ is_delete_popup_open: true, package_id: row._id })} sx={{ height: 30, width: 30, backgroundColor: '#1abcfd', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <AutoDeleteIcon style={{ height: 15, width: 15, color: '#fff' }} />
                                </Paper>


                              </Box>

                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Divider />
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.size}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </Box>



              </Card>

            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>



        <Modal
          open={this.state.is_delete_popup_open}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card sx={{ height: 200, width: { xs: '90%', sm: '60%', md: '30%' } }} variant="outlined">
              <Box>
                <Box sx={{ height: 36, width: 36, borderRadius: 5, backgroundColor: '#f1e3e0', display: 'flex', justifyContent: 'center', marginLeft: 2, marginTop: 2, alignItems: 'center' }}>
                  <AutoDeleteIcon style={{ height: 17, width: 17, color: '#d30d42' }} />
                </Box>
                <Typography sx={{ fontSize: 16, ml: 2, fontWeight: 'bold', mt: 0.5 }}>Delete Form</Typography>

                <Typography sx={{ fontSize: 13, ml: 2, mt: 1, color: '#77787c', mr: 10 }}>
                  Are you sure like to delete the form "BANNER DETAILS"
                </Typography>

                <br />
                <Box sx={{ height: 60, backgroundColor: '#ebebf5', mt: 1, display: 'flex', justifyContent: 'right', pr: 2 }}>
                  <Button size='small' sx={{ height: 30, mr: 1, mt: 1 }} variant='outlined' onClick={() => this.setState({ is_delete_popup_open: false })}>cancel</Button>
                  <Button size='small' onClick={this.delete} sx={{ height: 30, mt: 1 }} color='error' variant='contained'>Delete</Button>
                </Box>

              </Box>
            </Card>


          </Box>
        </Modal>



        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

      </div>
    )
  }
}



export function PackageListc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (<PackageList location={location} navigate={navigate}></PackageList>)
}





