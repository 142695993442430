import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem, Checkbox, Divider } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
const drawerWidth = 240




export class AddDiliveryBoy extends Component {
  constructor(props) {
    super(props)

    this.state = {

    }


    this.handleChange = this.handleChange.bind(this)
    this.handleChangee = this.handleChangee.bind(this)
  }



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  handleChangee = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    })
  }




  addCategory = () => {

  }



  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e4147' }}>Add Delivery Boy</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      href="/addBanners"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      Add Dilivery Boy
                    </Link>
                  </Breadcrumbs>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button onClick={() => this.props.navigate('/listCategory')} disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Delivery Boy List
                  </Button>
                </Box>
              </Card>





              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">

                <Typography sx={{ fontSize: { xs: 15, sm: 17, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 2, marginLeft: 2, fontWeight: '600', color: '#d30d42' }}>Add Delivery Boy</Typography>

                <Box sx={{ ml: 2, mr: 2 }}>

                  <Typography sx={{fontSize:15,fontWeight:600,color:'#1abcfd',mb:1}}>☞ Delivery Boy Information</Typography>
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>* Delivery Boy Name</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Photo(500 * 500)</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Staus</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Rating</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Vehical Number</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>* Mobile number(With country code + sign)</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />

                    </Grid>
                  </Grid>



                  <br />
                  <br />
                  <Typography sx={{fontSize:15,fontWeight:600,color:'#1abcfd',mb:1}}>☞ Delivery Boy Login Information</Typography>
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Email Id</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Password</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                  </Grid>





                  <br />
                  <br />
                  <Typography sx={{fontSize:15,fontWeight:600,color:'#1abcfd',mb:1}}>☞ Delivery Boy Address Information</Typography>
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Full Address</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Pincode</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>LandMark</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                  </Grid>



                  <br />
                  <br />
                  <Typography sx={{fontSize:15,fontWeight:600,color:'#1abcfd',mb:1}}>☞ Delivery Zone</Typography>
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>DeLiverY Zone</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    
                  </Grid>


                  <br />
                  <br />
                  <Typography sx={{fontSize:15,fontWeight:600,color:'#1abcfd',mb:1}}>☞ Commision</Typography>
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Delivery Commision</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    
                  </Grid>



                  <br />
                  <br />
                  <Typography sx={{fontSize:15,fontWeight:600,color:'#1abcfd',mb:1}}>☞ Delivery Boy Payout Information</Typography>
                  <Divider />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Bank Nmae</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Recepient</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>IFSC CODE</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Pay Pal ID</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Account No</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>UPI ID</Typography>
                      <TextField fullWidth size='small' InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                    </Grid>
                    
                  </Grid>
















                  <br />

                  <Button onClick={this.addCategory} startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                    Add Delivery Boy
                  </Button>

                  <br />
                  <br />

                </Box>
              </Card>






            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>
      </div>
    )
  }
}



export function AddDiliveryBoyc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  return (<AddDiliveryBoy location={location} navigate={navigate}></AddDiliveryBoy>)
}





