import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem, Checkbox, InputAdornment } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
const drawerWidth = 240




export class AddDish extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dish_name: "",
            dish_img: "",
            status: "",
            price: "",
            is_veg: true,
            is_nonveg: false,
            description: "",
            category_name: "",
            category_id: "",
            sub_category: "",
            sub_category_id: "",







            categoryList: [],
            subCategoryList: [],
        }


        this.handleChange = this.handleChange.bind(this)
        this.handleChangee = this.handleChangee.bind(this)
    }



    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    handleChangee = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }




    addCategory = () => {

    }




    componentDidMount() {

        fetch(`${statics.baseUrl}/retriveCategoryList`, {
            headers: {
                'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'content-type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({

            })
        }).then((res) => { return res.json() }).then((result) => {
            this.setState({
                categoryList: result.data
            })

        })

    }




addDish=()=>{

 let formData = new FormData();
 formData.append('dish_name',this.state.dish_name)
 formData.append('dish_img',this.state.dish_img)
 formData.append('price',this.state.price)
 formData.append('status',this.state.status)
 formData.append('is_veg',this.state.is_veg)
 formData.append('is_nonveg',this.state.is_nonveg)
 formData.append('description',this.state.description)
 formData.append('category_name',this.state.category_name)
 formData.append('category_id',this.state.category_id)
 formData.append('sub_category',this.state.sub_category)
 formData.append('sub_category_id',this.state.sub_category_id)

 if(this.state.dish_name!=="" && this.state.dish_img!=="" && this.state.price>=0 && this.state.status!=="" && this.state.description!=="" && this.state.category_name!=="" && this.state.category_id!=="" && this.state.sub_category!=="" && this.state.sub_category_id!==""){
 fetch(`${statics.baseUrl}/addDish`, {
    headers: {
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      // 'content-type':'application/json',
    },
    method: 'post',
    body: formData,

  }).then((res) => { return res.json() }).then((result) => {
   this.props.navigate('/dishList')
  })

}else{
    alert("fill all  field")
}

}



    render() {
        return (
            <div>
                <Box sx={{ display: 'flex' }}>
                    <Sidebarc />
                    <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
                        <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
                            <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Add Dish</Typography>



                            <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                                            Dashboard
                                        </Link>

                                        <Link
                                            underline="hover"
                                            color="text.primary"
                                            href="/addDish"
                                            aria-current="page"
                                            sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                                        >
                                            Add Dish
                                        </Link>
                                    </Breadcrumbs>
                                </Box>

                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                                    <Button onClick={() => this.props.navigate('/dishList')} disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                                        Dish List
                                    </Button>
                                </Box>
                            </Card>





                            <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">
                                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Add Dish</Typography>
                                <Box sx={{ ml: 2, mr: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Dish Name</Typography>
                                            <TextField fullWidth size='small' value={this.state.dish_name} name='dish_name' onChange={this.handleChange} InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Dish Image (500 * 500)</Typography>
                                            <TextField fullWidth size='small' InputProps={{
                                                sx: { fontSize: 11, fontWeight: '600', color: '#3e3e40' },
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <input type='file' accept="image/png, image/jpeg" name='dish_img'  onChange={this.handleChangee} />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Dish Staus</Typography>
                                            <TextField size='small' name='status' value={this.state.status} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 12, ml: -0.1} }}>
                                                {
                                                    statics.statusArray.map((i) => (
                                                        <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i.id} value={i.name} onClick={() => this.setState({ status: i.name })}>
                                                            {
                                                                i.name
                                                            }
                                                        </MenuItem>
                                                    ))
                                                }
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Price</Typography>
                                            <TextField fullWidth size='small' name='price' value={this.state.price} onChange={this.handleChange} InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                                                <Checkbox size='small' checked={this.state.is_veg} onChange={() => this.state.is_veg ? this.setState({ is_veg: false }) : this.setState({ is_veg: true })} />
                                                <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>is veg</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                                                <Checkbox size='small' checked={this.state.is_nonveg} onChange={() => this.state.is_nonveg ? this.setState({ is_nonveg: false }) : this.setState({ is_nonveg: true })} />
                                                <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>is non-veg</Typography>
                                            </Box>

                                        </Grid>
                                    </Grid>


                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Dish Description</Typography>
                                        <TextField
                                            value={this.state.description}
                                            onChange={this.handleChange}
                                            InputProps={{ sx: { fontSize: 12, fontWeight: '600', color: '#3e3e40' } }}
                                            id="outlined-multiline-static"
                                            multiline
                                            name='description'
                                            placeholder='Description here'
                                            fullWidth
                                            rows={3}

                                        />
                                    </Box>




                                    <Box sx={{ mt: 2 }}>
                                        <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40' }}>Dish Category</Typography>
                                        <TextField size='small' name='category_name' value={this.state.category_name} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1 } }}>
                                            {
                                                this.state.categoryList.map((i) => (
                                                    <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i._id} value={i.category_name} onClick={() => this.setState({ category_name: i.category_name, category_id: i._id, sub_category:"", sub_category_id: "" }, () => {
                                                        fetch(`${statics.baseUrl}/retriveSubcategoryForList`, {
                                                            headers: {
                                                                'authorization': `Bearer ${sessionStorage.getItem('token')}`,
                                                                'content-type': 'application/json',
                                                            },
                                                            method: 'post',
                                                            body: JSON.stringify({
                                                                id: i._id
                                                            })
                                                        }).then((res) => { return res.json() }).then((result) => {
                                                            this.setState({
                                                                subCategoryList: result.data
                                                            })

                                                        })
                                                    })}>
                                                        {
                                                            i.category_name
                                                        }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>



                                        <Typography sx={{ fontSize: 12, fontWeight: '600', color: '#3e3e40', mt: 2 }}>Dish Sub Category</Typography>
                                        <TextField size='small' name='sub_category' value={this.state.sub_category} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1} }}>
                                            {
                                                this.state.subCategoryList.map((i) => (
                                                    <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i._id} value={i.sub_category_name} onClick={() => this.setState({ sub_category: i.sub_category_name, sub_category_id: i._id })}>
                                                        {
                                                            i.sub_category_name
                                                        }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>

                                    </Box>







                                    <br />

                                    <Button onClick={this.addDish} startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                                        Add Dish
                                    </Button>

                                    <br />
                                    <br />

                                </Box>
                            </Card>






                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
                    <Appheaderc />
                </Box>
            </div>
        )
    }
}



export function AddDishc(props) {
    const navigate = useNavigate();
    const location = useLocation();
    return (<AddDish location={location} navigate={navigate}></AddDish>)
}


