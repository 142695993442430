import React, { Component } from 'react'
import { Box, Grid, Paper, Typography, Card, Button, TextField, MenuItem } from '@mui/material'
import { Sidebarc } from '../Sidebar'
import { Appheaderc } from '../Appheader'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import AddIcon from '@mui/icons-material/Add';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import statics from '../static'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
const drawerWidth = 240




export class EditSubCategory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category_name: '',
      category_id: "",
      sub_category_name: "",


      categoryList :[],
    }


    this.handleChange = this.handleChange.bind(this)
    this.handleChangee = this.handleChangee.bind(this)
  }



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }




  handleChangee = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    })
  }





componentDidMount(){

  const id = this.props.params.id.replace(/[^\w\s]/gi, '');

  fetch(`${statics.baseUrl}/retriveSinglesubCategoryDetail`, {
    headers: {
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      'content-type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify({
      id: id
    })

  }).then((res) => { return res.json() }).then((data) => {
    this.setState({
      category_name: data.category_name,
      category_id: data.category_id,
      sub_category_name: data.sub_category_name,
    })
  })





  fetch(`${statics.baseUrl}/retriveCategoryList`, {
    headers: {
      'authorization': `Bearer ${sessionStorage.getItem('token')}`,
      'content-type': 'application/json',
    },
    method: 'post',
    body: JSON.stringify({
  
    })
  }).then((res) => { return res.json() }).then((result) => {
    this.setState({
      categoryList  :result.data
    })

  })

}






  editCategory = () => {
    const id = this.props.params.id.replace(/[^\w\s]/gi, '');
    if (this.state.category_id !== "" && this.state.category_name !== "" && this.state.sub_category_name !== "") {
      fetch(`${statics.baseUrl}/updateSubCategory`, {
        headers: {
          'authorization': `Bearer ${sessionStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
        method: 'put',
        body: JSON.stringify({
          category_name: this.state.category_name,
          category_id: this.state.category_id,
          sub_category_name: this.state.sub_category_name,
          id: id
        })
      }).then((res) => { return res.json() }).then((result) => {
       this.props.navigate('/subCategoryList')
      })
    }
  }



  render() {
    return (
      <div>
        <Box sx={{ display: 'flex' }}>
          <Sidebarc />
          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)`, xs: '100%' }, }}>
            <Box sx={{ p: { xs: 1, sm: 3, }, mt: 6 }}>
              <Typography sx={{ fontSize: { xs: 17, sm: 21, marginTop: 3, marginBottom: 3 }, mt: { xs: 2, sm: 2, md: 1 }, mb: 1, marginLeft: 1, fontWeight: '500', color: '#3e3e40' }}>Edit Sub-Category</Typography>



              <Card sx={{ height: 50, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} variant="outlined">
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/dashboard" sx={{ fontSize: 14, fontWeight: '600' }}>
                      Dashboard
                    </Link>

                    <Link
                      underline="hover"
                      color="text.primary"
                      href="/addBanners"
                      aria-current="page"
                      sx={{ fontSize: 14, fontWeight: '600', color: '#3e4147' }}
                    >
                      Edit Sub-Category
                    </Link>
                  </Breadcrumbs>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50, ml: 2, mr: 2 }}>
                  <Button onClick={() => this.props.navigate('/subCategoryList')} disableElevation startIcon={<FormatListBulletedIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#0792fb', textTransform: 'none' }}>
                    Sub Category List
                  </Button>
                </Box>
              </Card>





              <Card sx={{ minHeight: 150, width: '100%', mt: 2 }} variant="outlined">

                <Typography sx={{ fontSize: { xs: 14, sm: 15, marginTop: 3, marginBottom: 3 }, mt: { xs: 3, sm: 3, md: 3 }, mb: 1, marginLeft: 2, fontWeight: '500', color: '#3e3e40' }}>Add Category</Typography>

                <Box sx={{ ml: 2, mr: 2 }}>


                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, minWidth: 170, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography display='inline' sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Sub Category Name</Typography>
                    </Box>
                    <TextField size='small' name='sub_category_name' value={this.state.sub_category_name} onChange={this.handleChange} fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }} />
                  </Box>



                  <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                    <Box sx={{ height: 37, width: 100, backgroundColor: '#0792fb', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography sx={{ fontSize: 14, fontWeight: '600', color: '#fff' }}>Category</Typography>
                    </Box>
                    <TextField size='small' name='category_name' value={this.state.category_name} onChange={this.handleChange} select placeholder='select' fullWidth InputProps={{ sx: { fontSize: 14, ml: -0.1, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}>
                      {
                        this.state.categoryList.map((i) => (
                          <MenuItem sx={{ fontSize: 14, fontWeight: '600' }} key={i._id} value={i.category_name} onClick={() => this.setState({category_name :  i.category_name ,category_id : i._id})}>
                            {
                              i.category_name
                            }
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Box>

                  <br />

                  <Button onClick={this.editCategory} startIcon={<AddIcon />} size='small' variant='contained' sx={{ height: 30, fontSize: 13, backgroundColor: '#d30d42', textTransform: 'none' }}>
                    Edit  Sub Category
                  </Button>

                  <br />
                  <br />

                </Box>
              </Card>






            </Box>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: { xs: 0, sm: 240 } }}>
          <Appheaderc />
        </Box>
      </div>
    )
  }
}



export function EditSubCategoryc(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  return (<EditSubCategory params={params} location={location} navigate={navigate}></EditSubCategory>)
}





